<template>
    <BaseLayout>
        <template #header>
            <div class="row g-2 align-items-center">
                <div class="col">
                    <!-- Page pre-title -->
                    <div class="page-pretitle">StreamerFun</div>
                    <h2 class="page-title">Виджеты</h2>
                </div>
            </div>
        </template>

        <div class="row row-deck row-cards">
            <div class="col-12" v-if="!user">
                <div class="card card-md">
                    <div class="card-stamp card-stamp-lg">
                        <div class="card-stamp-icon bg-primary">
                            <!-- Download SVG icon from http://tabler-icons.io/i/ghost -->
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                ></path>
                                <path
                                    d="M5 11a7 7 0 0 1 14 0v7a1.78 1.78 0 0 1 -3.1 1.4a1.65 1.65 0 0 0 -2.6 0a1.65 1.65 0 0 1 -2.6 0a1.65 1.65 0 0 0 -2.6 0a1.78 1.78 0 0 1 -3.1 -1.4v-7"
                                ></path>
                                <line x1="10" y1="10" x2="10.01" y2="10"></line>
                                <line x1="14" y1="10" x2="14.01" y2="10"></line>
                                <path d="M10 14a3.5 3.5 0 0 0 4 0"></path>
                            </svg>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-10">
                                <img
                                    :src="require('@/assets/logo-small.png')"
                                    style="height: 90px"
                                    alt=""
                                />
                                <div class="markdown text-muted mt-4">
                                    <div>
                                        <p>
                                            Мы рады предложить вам нашу
                                            коллекцию разнообразных виджетов,
                                            которые помогут вам привлекать новых
                                            зрителей, улучшать взаимодействие с
                                            аудиторией и дополнительно
                                            монетизировать ваши стримы.
                                        </p>
                                        <p>
                                            Наш сайт предлагает широкий выбор
                                            настроек и функций, которые помогут
                                            вам настроить виджеты, чтобы они
                                            соответствовали вашим потребностям и
                                            настроению трансляции.
                                        </p>
                                        <p>
                                            <a :href="$link.auth + '/register'"
                                                >Присоединяйтесь</a
                                            >
                                            к StreamerFun и используйте наши
                                            виджеты для создания
                                            профессиональных и захватывающих
                                            трансляций, которые привлекут новых
                                            зрителей и поддержат уже
                                            существующих!
                                        </p>
                                    </div>
                                </div>
                                <a
                                    :href="$link.auth + '/register'"
                                    class="btn btn-primary"
                                    @click="$metrika.reachGoal('register')"
                                >
                                    Создать аккаунт
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="col-12 col-sm-6 col-lg-3"
                v-for="(card, index) in list"
                :key="'widget_' + index"
            >
                <router-link
                    :to="card.to"
                    v-if="!card.is_plus || (user && user.plus)"
                    class="card card-link card-link-pop"
                >
                    <WidgetCard :card="card" />
                </router-link>
                <div
                    @click="openPlus(card)"
                    v-if="card.is_plus && (!user || !user.plus)"
                    class="card card-link card-link-pop"
                >
                    <WidgetCard :card="card" :locked="true" />
                </div>
            </div>
        </div>

        <div class="row align-self-center justify-content-center my-5">
            <div class="col-auto">
                <img
                    src="@/assets/services/twitch.png"
                    class="img-service"
                    alt="Twitch"
                />
            </div>
            <div class="col-auto">
                <img
                    src="@/assets/services/donationalerts.png"
                    class="img-service"
                    alt="DonationAlerts"
                />
            </div>
            <div class="col-auto">
                <img
                    src="@/assets/services/destream.png"
                    class="img-service"
                    alt="destream"
                />
            </div>
            <div class="col-auto">
                <img
                    src="@/assets/services/streamelements.png"
                    class="img-service"
                    alt="StreamElements"
                />
            </div>
        </div>

        <div class="row row-deck row-cards">
            <div
                class="col-12 col-sm-6 col-lg-3"
                v-for="(card, index) in interactive"
                :key="'widget_' + index"
            >
                <router-link
                    :to="card.to"
                    v-if="!card.is_plus || (user && user.plus)"
                    class="card card-link card-link-pop"
                >
                    <WidgetCard :card="card" />
                </router-link>
                <div
                    @click="openPlus(card)"
                    v-if="card.is_plus && (!user || !user.plus)"
                    class="card card-link card-link-pop"
                >
                    <WidgetCard :card="card" :locked="true" />
                </div>
            </div>
        </div>
    </BaseLayout>

    <need-plus-modal ref="needPlus">
        Для использования этого виджета нужна подписка StreamerPlus.
    </need-plus-modal>
</template>

<script>
import BaseLayout from "@/layouts/Base";
import WidgetCard from "@/views/Index/WidgetCard";
import { useHead } from "@vueuse/head";
import NeedPlusModal from "@/components/NeedPlusModal";
export default {
    name: "IndexView",
    components: { NeedPlusModal, WidgetCard, BaseLayout },
    computed: {
        user() {
            return this.$store.getters.user;
        },
    },
    data: () => {
        return {
            list: [
                {
                    name: "Колесо",
                    description:
                        "Позволяет проводить случайную лотерею среди участников. На экране появляется колесо, на котором расположены различные призы или награды.",
                    to: { name: "wheel" },
                    background: {
                        image: require("@/assets/widgets-preview/wheel.png"),
                    },
                    is_new: false,
                    is_plus: false,
                    is_enabled: true,
                },
                {
                    name: "Battle Royal",
                    description:
                        "Соревнование между несколькими элементами, которые будут случайным образом разбиты на отдельные матчи, где они будут сражаться друг с другом до тех пор, пока не останется только один победитель.",
                    to: { name: "royal" },
                    background: {
                        image: require("@/assets/widgets-preview/br2.png"),
                    },
                    is_new: false,
                    is_plus: false,
                    is_enabled: true,
                },
                {
                    name: "Таймер",
                    description:
                        "Запускает различные действия по истечению установленного времени. Может быть использована для проведения розыгрышей призов или установки лимитов на игровое время.",
                    to: { name: "timer" },
                    background: {
                        image: require("@/assets/widgets-preview/timer.png"),
                    },
                    is_new: false,
                    is_plus: false,
                    is_enabled: true,
                },
                // {
                //     name: "Аукцион",
                //     description: "Аукцион...",
                //     to: { name: "auction" },
                //     is_new: true,
                //     is_plus: false,
                //     is_enabled: true,
                // },
                // {
                //     name: "Опрос",
                //     description: "Опрос...",
                //     to: { name: "poll" },
                //     is_new: false,
                //     is_plus: true,
                //     is_enabled: false,
                // },
                // {
                //     name: "Розыгрыш",
                //     description: "Розыгрыш...",
                //     to: { name: "chatdraw" },
                //     is_new: false,
                //     is_plus: true,
                //     is_enabled: false,
                // },
                // {
                //     name: "Дуэль",
                //     description: "Дуэль...",
                //     to: { name: "duel" },
                //     is_new: false,
                //     is_plus: true,
                //     is_enabled: false,
                // },
                // {
                //     name: "Донат",
                //     description: "Донат...",
                //     to: { name: "donate" },
                //     is_new: true,
                //     is_plus: false,
                //     is_enabled: true,
                // },
                // {
                //     name: "Лобби",
                //     description: "Лобби...",
                //     to: { name: "lobby" },
                //     is_new: false,
                //     is_plus: true,
                //     is_enabled: false,
                // },
                {
                    name: "Ставки",
                    description: "Виджет для отображения прогнозов Twitch.",
                    to: { name: "prediction" },
                    background: {
                        image: require("@/assets/widgets-preview/prediction.png"),
                    },
                    is_new: false,
                    is_plus: true,
                    is_enabled: true,
                },
                // {
                //     name: "Награды",
                //     description: "Награды...",
                //     to: { name: "reward" },
                //     is_new: false,
                //     is_plus: true,
                //     is_enabled: false,
                // },
                // {
                //     name: "Say",
                //     description: "FakeSay...",
                //     to: { name: "say" },
                //     is_new: false,
                //     is_plus: true,
                //     is_enabled: false,
                // },
                // {
                //     name: "Топ",
                //     description: "Топы...",
                //     to: { name: "top" },
                //     is_new: false,
                //     is_plus: true,
                //     is_enabled: false,
                // },
            ],
            interactive: [
                {
                    name: "Project Zomboid Interactive",
                    description: "Интерактив для Project Zomboid.",
                    to: { name: "zomboid" },
                    background: {
                        image: require("@/assets/widgets-preview/zomboid.png"),
                    },
                    is_new: true,
                    is_plus: false,
                    is_enabled: true,
                },
            ],
        };
    },
    methods: {
        openPlus(card) {
            if (this.user.plus) {
                this.$router.push(card.to);
            } else {
                this.$refs.needPlus.openPlus();
            }
        },
    },
    beforeCreate() {
        useHead({
            title: "Главная",
            meta: [
                {
                    name: "description",
                    content: "Сайт с виджетами для стримера.",
                },
            ],
        });
    },
};
</script>

<style scoped lang="scss">
.img-service {
    width: 150px;
    margin: 0 15px;
}
</style>
