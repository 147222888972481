export default [
    /*{
        name: "guides",
        path: "/guides",
        component: () => import("@/guides"),
    },*/
    {
        name: "zomboid",
        path: "/interactive/zomboid",
        component: () => import("@/interactive/ZomboidInteractive.vue"),
    },
];
