export default [
    /*{
        name: "guides",
        path: "/guides",
        component: () => import("@/guides"),
    },*/
    {
        name: "howToStart",
        path: "/guide/how-to-start",
        component: () => import("@/guides/HowToStart"),
    },
    {
        name: "howToInstallWidgetPrediction",
        path: "/guide/twitch/how-to-install-widget-prediction",
        component: () => import("@/guides/Twitch/HowToInstallWidgetPrediction"),
    },
    {
        name: "wheelInfo",
        path: "/guide/widgets/wheel-info",
        component: () => import("@/guides/Widgets/WheelInfo"),
    },
    {
        name: "timerInfo",
        path: "/guide/widgets/timer-info",
        component: () => import("@/guides/Widgets/TimerInfo"),
    },
    {
        name: "datasetInfo",
        path: "/guide/widgets/dataset-info",
        component: () => import("@/guides/Widgets/DatasetInfo"),
    },
    {
        name: "battleRoyalInfo",
        path: "/guide/widgets/battle-royal-info",
        component: () => import("@/guides/Widgets/BattleRoyalInfo"),
    },
];
