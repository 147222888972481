import { createRouter, createWebHistory } from "vue-router";
// import HomeView from "../views/HomeView.vue";
import IndexView from "../views/Index";

import wheel from "./modules/wheel";
import auction from "@/router/modules/auction";
import bot from "@/router/modules/bot";
import carousel from "@/router/modules/carousel";
import chat from "@/router/modules/chat";
import chatdraw from "@/router/modules/chatdraw";
import chest from "@/router/modules/chest";
import donate from "@/router/modules/donate";
import duel from "@/router/modules/duel";
import lobby from "@/router/modules/lobby";
import poll from "@/router/modules/poll";
import sound from "@/router/modules/sound";
import timer from "@/router/modules/timer";
import topModule from "@/router/modules/top";
import treasure from "@/router/modules/treasure";
import prediction from "@/router/modules/prediction";
import reward from "@/router/modules/reward";
import plus from "@/router/modules/plus";
import battleroyal from "@/router/modules/battleroyal";
import settings from "@/router/modules/settings";
import NotFound from "@/errors/NotFound";
import youtube from "@/router/modules/youtube";
import guides from "@/router/modules/guides";
import rules from "@/router/modules/rules";
import interactive from "@/router/modules/interactive";

const routes = [
    {
        path: "/",
        name: "index",
        component: IndexView,
    },
    {
        path: "/upgrade",
        name: "upgrade",
        component: () =>
            import(/* webpackChunkName: "upgrade" */ "../views/Upgrade"),
    },
    // {
    //     path: "/about",
    //     name: "about",
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () =>
    //         import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    // },
    ...plus,
    ...wheel,
    ...auction,
    ...bot,
    ...carousel,
    ...chat,
    ...chatdraw,
    ...chest,
    ...donate,
    ...duel,
    ...lobby,
    ...poll,
    ...sound,
    ...timer,
    ...topModule,
    ...treasure,
    ...prediction,
    ...reward,
    ...battleroyal,
    ...settings,
    ...youtube,
    ...guides,
    ...rules,
    ...interactive,

    { path: "/404", name: "404", component: NotFound },
    { path: "/:pathMatch(.*)*", name: "not-found", redirect: "/404" },
    // if you omit the last `*`, the `/` character in params will be encoded when resolving or pushing
    { path: "/:pathMatch(.*)", name: "bad-not-found", redirect: "/404" },
];

const router = createRouter({
    history: createWebHistory("/"),
    routes,
});

export default router;
